import React from 'react';

import { Layout } from '../../modules/ui';
import { ThankYouPage, content } from '../../modules/thank-you-page';
import Seo from '../../components/SEO';

const ThankYouHRPage = () => {
    const footerRef = React.useRef();

    return (
        <>
            <Seo />
            <Layout footerRef={footerRef}>
                <ThankYouPage content={content.hr} footerRef={footerRef} />
            </Layout>
        </>
    );
};

export default ThankYouHRPage;
